import icon from "./assets/icon.svg";
import logo from "./assets/logo.svg";
import {css} from '@emotion/react';

import "./App.css";
function App() {
    return (
        <div>
            <div>
                <img src={icon} alt="Flap icon"
                     css={css`
                       width: 80px;
                       filter: drop-shadow(0 4px 10px rgb(0 0 0 / 0.25));
                     `}/>
            </div>
            <img src={logo} alt="Flap logo"
                 css={css`
                   width: 150px;
                   margin-top: 40px;
                   filter: drop-shadow(0 4px 5px rgb(0 0 0 / 0.25));
                 `}/>
        </div>
    );
}

export default App;
